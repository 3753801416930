.App {
  text-align: center;
  background-color: #0C0C0C;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #0C0C0C;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: #EEE; /* Soft white for readability against dark background */
  padding: 20px;
}

.tagline {
  font-weight: 500; 
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #009BCE; /* Your primary logo color for emphasis */
}

.coming-soon {
  font-weight: 300;
  color: #AAA; /* Slightly muted color for a premium feel */
  font-size: 1em;
}

.App-link {
  color: #009BCE;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 3px;
  border: 1px solid #009BCE;
  transition: all 0.2s ease-in-out;
}

.App-link:hover {
  background-color: #009BCE;
  color: #0C0C0C;
}
