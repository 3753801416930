@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html, body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
